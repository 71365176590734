<template>
  <div v-if="device !== null">
    <v-card>
      <v-card-title>{{ device.id }}</v-card-title>
      <v-card-subtitle>App Version: {{ device.appVersion }} | Ip: {{ device.deviceLog.IpAddress }} | Last Login: {{ device.lastSeenInMillis | moment('MMMM Do YYYY, HH:MM:ss') }}</v-card-subtitle>
    </v-card>
    <v-tabs v-model="selectedTab">
      <template v-for="deviceCategory in deviceSettingCategories">
        <v-tab
          :key="deviceCategory.key"
          :value="deviceCategory.key"
          :href="'#' + deviceCategory.key"
          :link="false"
        >
          {{ deviceCategory.name }}
        </v-tab>
      </template>
    </v-tabs>
    <v-card
      class="mb-2"
      :key="device.id"
    >
      <ActionList
        :empty-text="$t('noSettingsFound')"
        :preloaded-items-list="deviceSettings"
      >
        <template v-slot:item="{ item, index }">
          <BooleanDeviceSetting
            :key="item + 'bool'"
            :device="device"
            :default-device-setting="item"
            v-if="typeof item.defaultValue === 'boolean'"
          />
          <StringDeviceSetting
            :key="item + 'string'"
            :device="device"
            :default-device-setting="item"
            v-if="typeof item.defaultValue === 'string'"
          />
          <v-divider
            inset
            :key="index"
          />
        </template>
      </ActionList>
    </v-card>
  </div>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import BooleanDeviceSetting from "@/components/device/BooleanDeviceSetting"
import StringDeviceSetting from "@/components/device/StringDeviceSetting"

export default {
    name: 'DeviceProfile',
    props: ['device'],
    components: {
      ActionList,
      BooleanDeviceSetting,
      StringDeviceSetting
    },
    data() {
      return {
        selectedTab: 'general'
      }
    },

    computed: {
      deviceSettings() {
        return this.defaultDeviceSettings.booleans.filter(setting => setting.category === this.selectedTab)
      },
      deviceSettingCategories() {
        return this.$store.state.deviceSettingCategories
      },
      defaultDeviceSettings() {
        return this.$store.state.defaultDeviceSettings
      }
    }
}
</script>
