<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ defaultDeviceSetting.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
    name: 'StringDeviceSetting',
    props: ['device', 'defaultDeviceSetting']
}
</script>