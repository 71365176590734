<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ defaultDeviceSetting.title }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-checkbox
        :loading="processing"
        :disabled="processing"
        v-model="value"
        @change="valueChanged"
      />
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
    name: 'BooleanDeviceSetting',
    props: ['device', 'defaultDeviceSetting'],
    data() {
        return {
            value: false,
            processing: false,
        }
    },
    methods: {
      valueChanged(newValue) {
        this.processing = true
        this.$store.dispatch('updateBooleanDeviceSetting', {
          device: this.device.id,
          key: this.defaultDeviceSetting.key,
          value: newValue
        }).finally(() => {
          this.processing = false
        })
      }
    },
    created() {
        this.value = this.defaultDeviceSetting.defaultValue
        if(this.actualSetting !== null) {
            this.value = this.actualSetting.value
        }
    },
    computed: {
        actualSetting() {

            if(this.device.deviceLog !== null && this.device.deviceLog.DeviceSettings !== null && this.device.deviceLog.DeviceSettings.booleans !== null) {
                return this.device.deviceLog.DeviceSettings.booleans.find(deviceSetting => deviceSetting.key === this.defaultDeviceSetting.key)
            }

            return null
        }
    }
}
</script>